// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-en-tsx": () => import("./../../../src/pages/404.en.tsx" /* webpackChunkName: "component---src-pages-404-en-tsx" */),
  "component---src-pages-help-cookies-en-tsx": () => import("./../../../src/pages/help/cookies.en.tsx" /* webpackChunkName: "component---src-pages-help-cookies-en-tsx" */),
  "component---src-pages-help-cookies-ru-tsx": () => import("./../../../src/pages/help/cookies.ru.tsx" /* webpackChunkName: "component---src-pages-help-cookies-ru-tsx" */),
  "component---src-pages-help-privacy-en-tsx": () => import("./../../../src/pages/help/privacy.en.tsx" /* webpackChunkName: "component---src-pages-help-privacy-en-tsx" */),
  "component---src-pages-help-privacy-ru-tsx": () => import("./../../../src/pages/help/privacy.ru.tsx" /* webpackChunkName: "component---src-pages-help-privacy-ru-tsx" */),
  "component---src-pages-index-en-tsx": () => import("./../../../src/pages/index.en.tsx" /* webpackChunkName: "component---src-pages-index-en-tsx" */),
  "component---src-pages-kids-en-tsx": () => import("./../../../src/pages/kids.en.tsx" /* webpackChunkName: "component---src-pages-kids-en-tsx" */),
  "component---src-pages-news-and-sports-en-tsx": () => import("./../../../src/pages/news-and-sports.en.tsx" /* webpackChunkName: "component---src-pages-news-and-sports-en-tsx" */),
  "component---src-pages-shows-en-tsx": () => import("./../../../src/pages/shows.en.tsx" /* webpackChunkName: "component---src-pages-shows-en-tsx" */)
}

